import React, { useState, forwardRef } from "react";
import "./CInput.css";
import { CInputProps } from "./CInput.types";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const CInput: React.FC<CInputProps> = forwardRef(
  (
    {
      value,
      id,
      onChange,
      placeholder,
      type = "text",
      variant,
      defaultCountry,
      disabled,
      required,
      style,
      className = "",
      maxLength,
      minLength,
      preLabel,
      inputMode,
      readonly,
      ...rest
    },
    ref
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
      setIsPasswordVisible(!isPasswordVisible);
    };

    return (
      <div className="cinput__container">
        {preLabel && (
          <p className="cinput__preLabel">
            {preLabel} <span>|</span>
          </p>
        )}

        <input
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={type === "password" && !isPasswordVisible ? "password" : "text"}
          disabled={disabled}
          required={required}
          style={style}
          className={`cinput ${className} cinput-${preLabel ? "preLabel" : ""}`}
          ref={ref}
          maxLength={maxLength}
          minLength={minLength}
          inputMode={inputMode}
          readOnly={readonly}
          id={id}
          {...rest}
        />
        {type === "password" && (
          <div className="cinput__icon">
            {isPasswordVisible ? (
              <FaEyeSlash onClick={togglePasswordVisibility} />
            ) : (
              <FaEye onClick={togglePasswordVisibility} />
            )}
          </div>
        )}
      </div>
    );
  }
);

export default CInput;
