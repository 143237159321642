import React, { useEffect } from "react";
import "./Support.css";
import CDevider from "../../../components/CDevider";
import { ticketStore } from "../../../stores/ticketStore";
import { observer } from "mobx-react-lite";
import { t } from "i18next";

const Support: React.FC = observer(() => {
  useEffect(() => {
    ticketStore.fetchContact();
  }, []);

  return (
    <div className="support">
      <div className="support__contacts">
        <p className="contacts__title">{t("support.contactsTitle")}:</p>

        <div className="contact__phones">
          {ticketStore.contact?.phones.map((phone, index) => {
            return (
              <a key={index} href={`tel:${phone}`} className="contact__phone">
                {phone}
              </a>
            );
          })}
        </div>
      </div>

      <CDevider />

      <div className="support__socials">
        <p className="socials__title">{t("support.socialsTitle")}:</p>

        <div className="contact__socials">
          {ticketStore.contact?.socials.map((social, index) => {
            return (
              <a
                key={index}
                href={social.url}
                target="_blank"
                rel="noreferrer"
                className="socials__social"
              >
                <img src={social.icon} alt={social.title} />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default Support;
