import React from "react";
import "./ChangePassword.css";
import SectionHeader from "../../layouts/SectionHeader";
import { t } from "i18next";
import CInput from "../../components/CInput";
import CButton from "../../components/CButton";
import { useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { accountStore } from "../../stores/accountStore";
import CModal from "../../components/CModal";
import { Link } from "react-router-dom";

interface IData {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePassword: React.FC = observer(() => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IData>();
  const newPassword = watch("newPassword");

  const onSumbit = (data: IData) => {
    accountStore.changePassword(data.oldPassword, data.newPassword);
  };

  return (
    <>
      <SectionHeader title={t("labels.changePasswordTitle")} />

      <form
        className="change__password__form wrapper"
        onSubmit={handleSubmit(onSumbit)}
      >
        <div>
          <p className="change__password__form__title">
            {t("changePassword.enterAcountPassword")}
          </p>
          <CInput
            type="password"
            placeholder={t("changePassword.enterPassword")}
            {...register("oldPassword", {
              required: t("labels.required"),
              minLength: {
                value: 6,
                message: t("error.passwordMinLength"),
              },
            })}
          />
          {errors.oldPassword && (
            <p className="danger__text change__password__form__error">
              {errors.oldPassword.message}
            </p>
          )}
        </div>

        <div>
          <p className="change__password__form__title">
            {t("changePassword.createPassword")}
          </p>
          <CInput
            type="password"
            placeholder={t("changePassword.enterPassword")}
            {...register("newPassword", {
              required: t("labels.required"),
              minLength: {
                value: 6,
                message: t("error.passwordMinLength"),
              },
            })}
          />
          {errors.newPassword && (
            <p className="danger__text change__password__form__error">
              {errors.newPassword.message}
            </p>
          )}
        </div>

        <div>
          <p className="change__password__form__title">
            {t("changePassword.confirmPassword")}
          </p>
          <CInput
            type="password"
            placeholder={t("changePassword.enterPassword")}
            {...register("confirmPassword", {
              required: t("labels.required"),
              minLength: {
                value: 6,
                message: t("error.passwordMinLength"),
              },
              validate: (value) =>
                value === newPassword || t("error.passwordsNotMatch"),
            })}
          />
          {errors.confirmPassword && (
            <p className="danger__text change__password__form__error">
              {errors.confirmPassword.message}
            </p>
          )}
        </div>

        <CButton
          title={t("button.confirm")}
          isLoading={accountStore.isChangingPassword}
        />
      </form>

      <CModal
        isOpen={accountStore.changePasswordResult}
        content={
          <div className="change__password__modal">
            <p className="change__password__modal__title">
              {t("changePassword.passwordChanged")}
            </p>

            <Link
              className="change__password__modal__button"
              to={"/profile/settings"}
            >
              <CButton
                title={t("button.ok")}
                onClick={accountStore.toggleChangePasswordResult}
              />
            </Link>
          </div>
        }
      />
    </>
  );
});

export default ChangePassword;
