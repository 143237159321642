import React, { useCallback, useEffect, useState } from "react";
import "./TransferConfirm.css";
import SectionHeader from "../../../layouts/SectionHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ICreateCardTransactionResponse } from "../../../models/ICreateCardTransactionResponse";
import { useTranslation } from "react-i18next";
import CButton from "../../../components/CButton";
import CDevider from "../../../components/CDevider";
import { amountAddSpace } from "../../../utils/utils";
import { ICard } from "../../Payment/Cards/ICard";
import { cardStore } from "../../../stores/cardStore";
import CCardSwiper from "../../../components/CCardSwiper";

const TransferConfirm: React.FC = () => {
  const [cards, setCards] = useState<ICard[]>([]);
  const [amount, setAmount] = useState<String>();
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [isLoadingTransaction, setIsLoadingTransaction] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedCard, setSelectedCard] = useState<ICard>();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { transaction } = location.state as {
    transaction: ICreateCardTransactionResponse;
  };

  const fetchCards = useCallback(async () => {
    try {
      setIsLoadingCard(true);

      let response = await cardStore.getList();

      response = response.filter((card: ICard) => card.type !== "19");

      setSelectedCard(response[0]);

      setCards(response);
    } catch (error) {
      console.log("Error in fetchCards in TransferConfirm", error);
    } finally {
      setIsLoadingCard(false);
    }
  }, []);

  const processCardTransaction = async () => {
    try {
      setIsLoadingTransaction(true);

      const response = await cardStore.processCardTransaction(
        transaction?.id,
        selectedCard?.id
      );

      setMessage(response.message);

      if (response.needConfirm) {
        navigate(`/transfer/confirmSms/${transaction.id}`, {
          state: { response },
        });
      } else if (!response.needConfirm && !response.message) {
        navigate(`/transfer/result/${transaction.id}`, { state: { response } });
      }
    } catch (error) {
      console.log("Error in processCardTransaction", error);
    } finally {
      setIsLoadingTransaction(false);
    }
  };

  useEffect(() => {
    const totalAmount = transaction?.cards?.find(
      (card) => card.id === selectedCard?.id
    )?.totalAmountS;

    setAmount(totalAmount);
  }, [selectedCard, transaction]);

  useEffect(() => {
    fetchCards();
  }, [fetchCards]);

  return (
    <>
      <SectionHeader title={t("transaction.confirmTransaction")} />

      <div className="transferConfirm wrapper">
        <div className="transferConfirm__content">
          <div className="transferConfirm__info">
            <p className="transferConfirm__label">
              {t("aboutTransaction.ownerCard")}
            </p>
            <p className="transferConfirm__value">
              {transaction.receiver?.number}
            </p>
          </div>

          <div className="transferConfirm__info">
            <p className="transferConfirm__label">
              {t("aboutTransaction.receiver")}
            </p>
            <p className="transferConfirm__value">
              {transaction.receiver?.owner}
            </p>
          </div>

          <CDevider />

          <div className="transferConfirm__info">
            <p className="transferConfirm__label">
              {t("aboutTransaction.amountPayment")}
            </p>
            <p className="transferConfirm__value">
              {amountAddSpace(Number(transaction.amount))}{" "}
              {t("aboutTransaction.sum")}
            </p>
          </div>

          <div className="transferConfirm__info">
            <p className="transferConfirm__label">
              {t("aboutTransaction.amountDebit")}
            </p>
            <p className="transferConfirm__value">{amount}</p>
          </div>
        </div>

        <div className="transferConfirm__wrapper">
          <p className="error__message">{message}</p>

          {cards?.length > 0 && (
            <CCardSwiper
              cards={cards}
              setSelectedCard={setSelectedCard}
              isLoading={isLoadingCard}
            />
          )}

          <div className="transferConfirm__actions">
            <Link to={"/transfer"} className="transferConfirm__link">
              <CButton title={t("button.goBack")} />
            </Link>

            <CButton
              title={t("button.confirm")}
              onClick={processCardTransaction}
              isLoading={isLoadingTransaction}
              disabled={cards?.length === 0}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TransferConfirm;
