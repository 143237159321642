import React, { useState } from "react";
import "./PincodeReset.css";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CBack from "../../../components/CBack";
import { themeStore } from "../../../stores/themeStore";
import CInput from "../../../components/CInput";
import CButton from "../../../components/CButton";
import { pincodeStore } from "../../../stores/pincodeStore";
import pwLight from "../../../assets/images/pw-light.svg";
import pwDark from "../../../assets/images/pw-dark.svg";
import CModal from "../../../components/CModal";

interface IFormData {
  code: string;
}

const PincodeReset: React.FC = () => {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IFormData> = async (data) => {
    try {
      setIsLoading(true);
      const response = await pincodeStore.resetPinCodeConfirm(data.code);

      if (response.message) {
        setMessage(response.message);
        return;
      }

      if (response) {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error during pincodeReset confirm:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="pincodeReset wrapper">
      <div style={{ width: "100%" }}>
        <CBack />
      </div>

      <img
        src={themeStore.isDarkTheme ? pwDark : pwLight}
        alt="pw"
        className="auth__logo"
      />

      <form className="pincodeReset__form" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <p className="pincodeReset__form__label">{t("code.title")}</p>
          <CInput
            placeholder={t("code.placeholder")}
            inputMode="numeric"
            maxLength={6}
            {...register("code", {
              required: t("error.codeRequired"),
              minLength: {
                value: 6,
                message: t("error.codeMinLength"),
              },
              maxLength: {
                value: 6,
                message: t("error.codeMaxLength"),
              },
            })}
          />

          {errors.code && (
            <span className="danger__text pincodeReset__error">
              {errors.code.message}
            </span>
          )}

          {message && <p className="error__message">{message}</p>}
        </div>

        <CButton
          title={t("labels.confirm")}
          variant="success"
          isLoading={isLoading}
        />
      </form>

      <CModal
        isOpen={isModalOpen}
        content={
          <div className="pincode__modal">
            <h2 className="pincode__modal__title">
              {t("pincode.pincodeSuccessfullyReset")}
            </h2>

            <div className="pincode__modal__actions">
              <CButton
                title={t("button.ok")}
                onClick={() => {
                  setIsModalOpen(false);
                  navigate("/pincode");
                }}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default PincodeReset;
