import React, { useEffect } from "react";
import "./History.css";
import { useTranslation } from "react-i18next";
import HistoryCard from "./HistoryCard";
import ExpenseOrProfit from "./ExpenseOrProfit";
import CLoading from "../../components/CLoading";
import { historyStore } from "../../stores/historyStore";
import { observer } from "mobx-react-lite";

const History: React.FC = observer(() => {
  const { t } = useTranslation();

  useEffect(() => {
    historyStore.fetchHistory();
  }, []);

  return (
    <div className="history wrapper">
      <ExpenseOrProfit />

      {/* <FilterCard /> */}

      <div className="historyCard__container">
        {historyStore.isLoadingHistory && (
          <div className="historyCard__loading">
            <CLoading />
          </div>
        )}

        {historyStore?.history?.items.length === 0 &&
          !historyStore.isLoadingHistory && (
            <p className="history__empty">{t("history.empty")}</p>
          )}

        {historyStore?.history?.items.map((item) => {
          return <HistoryCard history={item} key={item.id} />;
        })}
      </div>
    </div>
  );
});

export default History;
