import React, { useEffect } from "react";
import "./ActiveDevices.css";
import SectionHeader from "../../layouts/SectionHeader";
import { observer } from "mobx-react-lite";
import { FiTrash } from "react-icons/fi";
import CLoading from "../../components/CLoading";
import { accountStore } from "../../stores/accountStore";
import { useTranslation } from "react-i18next";

const ActiveDevices: React.FC = observer(() => {
  const { t } = useTranslation();

  useEffect(() => {
    accountStore.fetchSessions();
  }, []);
  return (
    <>
      <SectionHeader title={t("profile.activeDevices")} />

      <div className="active__devices wrapper">
        {accountStore.isLoadingSessions && (
          <div className="active__devices__loading">
            <CLoading />
          </div>
        )}

        {accountStore.sessions?.map((session) => {
          return (
            <div key={session.id} className="active__device__session">
              <div className="session__info">
                {session.current && (
                  <p className="session__text-current">
                    {t("session.current")}
                  </p>
                )}
                <p className="session__text">
                  {t("session.app")}: {session.app}
                </p>
                <p className="session__text">
                  {t("session.date")}: {session.created}
                </p>
                <p className="session__text">
                  {t("session.device")}: {session.device}
                </p>
                <p className="session__text">
                  {t("session.ip")}: {session.ip}
                </p>
                <p className="session__text">
                  {t("session.os")}: {session.os}
                </p>
              </div>

              {!session.current && (
                <button
                  className="session__disconnect"
                  onClick={() => accountStore.closeSession(session.id)}
                >
                  {t("session.disconnect")} <FiTrash />
                </button>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
});

export default ActiveDevices;
