import { makeAutoObservable, runInAction } from "mobx";
import apiClient from "../services/apiClient";
import { INotification } from "../models/INotification";

class NotificationStore {
  notifications: INotification[] = [];
  isLoadingNotifications: boolean = false;
  count: number = 0;
  isAllReadLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchNotifications = async () => {
    if (this.notifications.length > 0) {
      return this.notifications;
    }

    runInAction(() => {
      this.isLoadingNotifications = true;
    });

    try {
      const response = await apiClient.get<INotification[]>("/notifications");

      runInAction(() => {
        this.notifications = response.data;
      });
    } catch (error) {
      console.log("Error in fetchNotifications", error);
    } finally {
      runInAction(() => {
        this.isLoadingNotifications = false;
      });
    }
  };

  unreadCount = async () => {
    if (this.count > 0) {
      return this.count;
    }

    try {
      const response = await apiClient.get("/notifications/unreadCount");

      runInAction(() => {
        this.count = response.data.result;
      });

      return response.data;
    } catch (error) {
      console.log("Error in getAccountInfo", error);
    }
  };

  allRead = async () => {
    runInAction(() => {
      this.isAllReadLoading = true;
    });

    try {
      const response = await apiClient.patch("/notifications/allRead");

      this.count = 0;

      return response.data;
    } catch (error) {
      console.log("Error in allRead", error);
    } finally {
      runInAction(() => {
        this.isAllReadLoading = false;
      });
    }
  };
}

export const notificationStore = new NotificationStore();
