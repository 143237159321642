import React, { useEffect, useState } from "react";
import "./Notification.css";
import CBack from "../../components/CBack";
import { FiCheckSquare } from "react-icons/fi";
import { notificationStore } from "../../stores/notificationStore";
import moment from "moment";
import CLoading from "../../components/CLoading";
import { LuLoader } from "react-icons/lu";
import { observer } from "mobx-react-lite";
import { t } from "i18next";

const Notification: React.FC = observer(() => {
  const [activeTab, setActiveTab] = useState("NotificationTab");

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    notificationStore.fetchNotifications();
  }, []);

  return (
    <div className="notification wrapper">
      <div className="notification__header">
        <CBack />

        <p className="notification__mark" onClick={notificationStore.allRead}>
          {t("notification.readAll")}
          {notificationStore?.isAllReadLoading ? (
            <LuLoader className="notificationMark__loader" />
          ) : (
            <FiCheckSquare />
          )}
        </p>
      </div>

      <div className="notification__tabs">
        <button
          onClick={() => handleTabClick("NotificationTab")}
          className={`notification__tab ${
            activeTab === "NotificationTab" && "notification__tab-active"
          }`}
        >
          {t("notification.notification")}
        </button>

        <button
          onClick={() => handleTabClick("InvoiceTab")}
          className={`notification__tab ${
            activeTab === "InvoiceTab" && "notification__tab-active"
          }`}
        >
          {t("notification.invoices")}
        </button>
      </div>

      <div className="notification__tab__content">
        {activeTab === "NotificationTab" && (
          <div className="notification__container">
            {notificationStore.isLoadingNotifications && (
              <div className="notification__loading">
                <CLoading />
              </div>
            )}

            {notificationStore.notifications?.length === 0 &&
              !notificationStore.isLoadingNotifications && (
                <p className="notification__empty">{t("notification.empty")}</p>
              )}

            {notificationStore.notifications?.map((notification) => {
              return (
                <div className="notification__card" key={notification.id}>
                  <p className="notification__date">
                    {moment(notification.created).format("DD.MM.YYYY HH:mm")}
                  </p>
                  <p className="notification__title">{notification.title}</p>
                  <p
                    className="notification__text"
                    dangerouslySetInnerHTML={{
                      __html: (notification.text || "").replace(/\n/g, "<br>"),
                    }}
                  ></p>
                </div>
              );
            })}
          </div>
        )}

        {activeTab === "InvoiceTab" && (
          <p className="notification__empty">
            {t("notification.emptyInvoice")}
          </p>
        )}
      </div>
    </div>
  );
});

export default Notification;
