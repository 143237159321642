import { makeAutoObservable, runInAction } from "mobx";
import apiClient from "../services/apiClient";
import { IHistory } from "../models/IHistory";

class HistoryStore {
  historyCache = null;
  history: IHistory | null = null;
  isLoadingHistory: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchHistory = async (
    cardId?: number,
    lastTid?: number,
    from?: string,
    to?: string
  ) => {
    if (this.history) {
      return this.history;
    }

    const params = {
      cardId,
      lastTid,
      from,
      to,
    };

    runInAction(() => {
      this.isLoadingHistory = true;
    });

    try {
      const response = await apiClient.get<IHistory>("/history", { params });

      runInAction(() => {
        this.history = response.data;
      });
    } catch (error) {
      console.log("Error in fetchHistory", error);
    } finally {
      runInAction(() => {
        this.isLoadingHistory = false;
      });
    }
  };
}

export const historyStore = new HistoryStore();
