import React, { useEffect, useState } from "react";
import "./Header.css";
import {
  IoIosNotificationsOutline,
  IoMdRefresh,
  IoMdShare,
} from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { notificationStore } from "../../stores/notificationStore";
import { observer } from "mobx-react-lite";
import { balanceStore } from "../../stores/balanceStore";
import { cardStore } from "../../stores/cardStore";
import { amountAddSpace } from "../../utils/utils";
import { BiSupport } from "react-icons/bi";
import { LuLoader } from "react-icons/lu";
import { t } from "i18next";
import { pointStore } from "../../stores/pointStore";

const Header: React.FC = observer(() => {
  const [referralLink, setReferralLink] = useState("");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const balance = Number(cardStore.totalAmount.replaceAll(" ", "")).toFixed(2);

  const fetchReferralLink = async () => {
    try {
      const response = await pointStore.getInvite();

      setReferralLink(response?.result);
    } catch (error) {
      console.log("Error in fetchReferralLink", error);
    }
  };

  const shareReferralLink = () => {
    const referralMessage = `${t("share.financialAssistant")}\n${t(
      "share.payFor"
    )}\n${t("share.transferMoney")}\n${t("share.earnBonuses")}\n${t(
      "share.allDirectly"
    )}\n\n${t("share.try")} ${referralLink}`;

    if (window.Telegram?.WebApp?.openTelegramLink) {
      window.Telegram.WebApp.openTelegramLink(
        `https://t.me/share/url?url=${encodeURIComponent(referralMessage)}`
      );
    }
  };

  const refreshPage = () => {
    setIsRefreshing(true);

    window.location.reload();
  };

  useEffect(() => {
    fetchReferralLink();
    notificationStore.unreadCount();
  }, []);

  return (
    <header className="header wrapper">
      <div className="header__actions">
        <Link to={"/payment/notification"} className="header__notification">
          <IoIosNotificationsOutline />

          {notificationStore?.count > 0 && (
            <span className="header__notification-badge">
              {notificationStore?.count}
            </span>
          )}
        </Link>

        <div className="header__views" onClick={balanceStore.toggleBalance}>
          {balanceStore.showBalance ? <FaRegEyeSlash /> : <FaRegEye />}

          {balanceStore.showBalance && (
            <span className="header__balance">
              {amountAddSpace(Number(balance))} {t("balance.sum")}
            </span>
          )}
        </div>
      </div>

      <div className="header__actions">
        {isRefreshing ? (
          <LuLoader className="notificationMark__loader" />
        ) : (
          <IoMdRefresh onClick={refreshPage} />
        )}

        <a
          href="https://t.me/Tech_Payway"
          aria-label="tech payway"
          target="_blank"
          rel="noreferrer"
        >
          <BiSupport />
        </a>

        <IoMdShare onClick={shareReferralLink} />

        <Link to={"/payment/search"} aria-label="search">
          <IoSearch />
        </Link>
      </div>
    </header>
  );
});

export default Header;
