import { makeAutoObservable, runInAction } from "mobx";
import apiClient from "../services/apiClient";
import { IContact } from "../models/IContact";

class TicketStore {
  contact: IContact | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  fetchContact = async () => {
    if (this.contact) {
      return this.contact;
    }

    try {
      const response = await apiClient.get<IContact>("/ticket/contacts");

      runInAction(() => {
        this.contact = response.data;
      });
    } catch (error) {
      console.log("Error in fetchContact", error);
    }
  };
}

export const ticketStore = new TicketStore();
