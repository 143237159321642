import React, { useCallback, useEffect, useState } from "react";
import "./ServiceConfirm.css";
import SectionHeader from "../../../../layouts/SectionHeader";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ICard } from "../../Cards/ICard";
import { cardStore } from "../../../../stores/cardStore";
import CCardSwiper from "../../../../components/CCardSwiper";
import CButton from "../../../../components/CButton";
import CDevider from "../../../../components/CDevider";
import { amountAddSpace } from "../../../../utils/utils";
import { payStore } from "../../../../stores/payStore";

interface IField {
  note: string;
  value: string;
}

const ServiceConfirm: React.FC = () => {
  const [cards, setCards] = useState<ICard[]>([]);
  const [amount, setAmount] = useState<String>();
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedCard, setSelectedCard] = useState<ICard>();
  const { t } = useTranslation();
  const location = useLocation();
  const state = JSON.parse(location.state);
  const navigate = useNavigate();

  const fetchCards = useCallback(async () => {
    try {
      setIsLoadingCard(true);

      let response = await cardStore.getList();

      response = response.filter((card: ICard) => card.type !== "19");

      setSelectedCard(response[0]);

      setCards(response);
    } catch (error) {
      console.log("Error in fetchCards in serviceConfirm", error);
    } finally {
      setIsLoadingCard(false);
    }
  }, []);

  const payByCard = async () => {
    try {
      setIsLoading(true);

      const response = await payStore.payByCard(
        state.id,
        Number(selectedCard?.id)
      );

      setMessage(response.message);

      if (response.needConfirm) {
        navigate(`/payment/service/confirmSms/${state.id}`, {
          state: JSON.stringify({ response, cardId: selectedCard?.id }),
        });
      } else if (!response.needConfirm && !response.message) {
        navigate(`/payment/service/result/${state.id}`, {
          state: { response },
        });
      }
    } catch (error) {
      console.log("Error in payByCard in serviceConfirm", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const totalAmount = state?.cards?.find(
      (card: any) => card.id === selectedCard?.id
    )?.totalAmountS;

    setAmount(totalAmount);
  }, [selectedCard, state]);

  useEffect(() => {
    fetchCards();
  }, [fetchCards]);

  return (
    <>
      <SectionHeader title={t("transaction.confirmTransaction")} />

      <div className="serviceConfirm wrapper">
        <div className="serviceConfirm__content">
          {state.fields?.map((field: IField, index: number) => {
            return (
              <div className="serviceConfirm__info" key={index}>
                <p className="serviceConfirm__label">{field.note}</p>
                <p className="serviceConfirm__value">{field.value}</p>
              </div>
            );
          })}

          <CDevider />

          <div className="serviceConfirm__info">
            <p className="serviceConfirm__label">
              {t("aboutTransaction.amountPayment")}
            </p>
            <p className="serviceConfirm__value">
              {amountAddSpace(Number(state.payment.amount))}{" "}
              {t("aboutTransaction.sum")}
            </p>
          </div>

          <div className="serviceConfirm__info">
            <p className="serviceConfirm__label">
              {t("aboutTransaction.amountDebit")}
            </p>
            <p className="serviceConfirm__value">{amount}</p>
          </div>
        </div>

        <div className="serviceConfirm__wrapper">
          <p className="error__message">{message}</p>

          {cards?.length > 0 && (
            <CCardSwiper
              cards={cards}
              setSelectedCard={setSelectedCard}
              isLoading={isLoadingCard}
            />
          )}

          <div className="serviceConfirm__actions">
            <Link to={"/payment"} className="serviceConfirm__link">
              <CButton title={t("button.goBack")} />
            </Link>

            <CButton
              title={t("button.confirm")}
              onClick={payByCard}
              isLoading={isLoading}
              disabled={cards?.length === 0}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceConfirm;
