import i18next from "i18next";
import moment from "moment";

export const formatCardNumber = (cardNumber: string): string => {
  const formatedCardNumber = cardNumber.replace(/\s/g, "");

  return formatedCardNumber.match(/.{1,4}/g)?.join(" ") || "";
};

export const formatExpDate = (expDate: string) => {
  return expDate && `${expDate.slice(0, 2)}/${expDate.slice(2)}`;
};

export const amountAddSpace = (amount: number) => {
  return amount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const formatDateWithMonth = (date: string | Date) => {
  let newLocale = i18next?.language;

  if (i18next?.language?.includes("uz")) {
    newLocale = "uz-latn";
  } else if (i18next?.language?.includes("ru")) {
    newLocale = "ru";
  } else if (i18next?.language?.includes("en")) {
    newLocale = "en";
  } else {
    newLocale = "ru";
  }

  moment.locale(newLocale);
  const outputDate = moment(date).format("DD MMMM YYYY");
  moment.locale("en");

  return outputDate;
};

export const isMobileDevice = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();

  return /android|iphone|ipad|ipod|windows phone|blackberry|mobile/i.test(
    userAgent
  );
};
